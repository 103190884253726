/* This example requires Tailwind CSS v2.0+ */
import React from "react";
import { PageProps } from "gatsby";

import SEO from "../components/seo";
import NavBar from "../components/navBar";
import Footer from "../components/Footer";
import CalendarButton from "../components/CalendarButton";

// @ts-ignore
import hero from "../images/approach2.jpg";
import PrimaryButton from "../components/PrimaryButton";
import { DARK_MODE } from "../constants";

export default function About(props: PageProps) {
  const mainBg = DARK_MODE ? "bg-indigo-600" : "bg-white"
  const sideBg = DARK_MODE ? "bg-indigo-600" : "bg-gray-50"
  const headerText = DARK_MODE ? "text-white " : "text-indigo-600 "
  const mainText = DARK_MODE ? "text-slate-50	" : "text-gray-500"

  return (
    <div className={mainBg} id="root">
      <SEO
        title="Approach"
        description="Your insight is an important part of our process. We work together, communicating on all relevant things to your life and well-being. It’s an intimate process and I want to be able to support you the best I can, so it’s important to communicate face to face, even if it’s from opposite sides of the country."
        image={hero}
      />
      <NavBar {...props} />
      <main>
        <div className={mainBg + " overflow-hidden"}>
          <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
          <div className={sideBg + " hidden lg:block absolute top-0 bottom-0 left-3/4 w-screen"} />
            <div className="mx-auto text-base z-10 max-w-prose lg:grid lg:grid-cols-1 lg:gap-8 lg:max-w-none">
              <h3 className={headerText + "mt-2 text-3xl leading-8 contents lg:hidden font-extrabold tracking-tight sm:text-4xl"}>
                Approach
              </h3>
            </div>
            <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
              <div className="relative lg:row-start-1 lg:col-start-2">
                <div className="relative text-base mx-auto max-w-prose lg:max-w-none">
                  <figure>
                    <div className="aspect-w-12 aspect-h-12 lg:aspect-none">
                      <img
                        className="rounded-lg shadow-lg object-cover object-center"
                        src={hero}
                        alt="Picture of Anthony"
                      />
                    </div>
                  </figure>
                </div>
              </div>
              <div className="mt-8 lg:mt-0">
                <h3 className={headerText + "mt-8 text-3xl hidden lg:contents font-extrabold tracking-tight sm:text-4xl"}>
                  Approach
                </h3>
                <div className={mainText + " mt-5 prose text-lg lg:text-xl prose-indigo mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1"}>
                  <p>
                    There’s an overwhelming amount of information out there.
                  </p>
                  <p>
                    Regardless of personal interests, we get hit by notifications, advertisements, opinions, and expert advice every day. Naturally, we assume that the values of our lives are harmonious with our health until something goes awry and we are challenged to raise better questions.
                  </p>
                  <p>
                    The missing component is not information, it’s application. 
                  </p>
                  <p>
                    The heart of my work lives here, in the personal conversations where we can view things up close as well as zoom out to a birds-eye view to see complex systems at play. With this all-encompassing approach, we can simplify our direction and invest our resources toward what matters most.
                  </p>
                  <p>
                    This is the level of care I wish for my loved ones and it’s the care you too deserve.
                  </p>
                </div>
                <div className="mt-8 sm:mt-12 sm:flex sm:justify-center lg:justify-start">
                  {/* <CalendarButton /> */}
                  <PrimaryButton analyticsLink="MAIN_FREE_CHAT" text="Free 15 min Chat" href="/chat" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}
